<template>
  <!-- ======= Services Section ======= -->
  <section id="services" class="services">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Services</h2>
          <p>Here is the list of services we provide.</p>
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="bx bxl-dribbble"></i></div>
              <h4 class="title"><a href="">Windows and Doors Installation/Repair</a></h4>
              <p class="description">Our skilled team specializes in providing expert installation and repair services for windows and doors. We bring years of experience and a commitment to excellence to every project, ensuring that your windows and doors are installed correctly and function smoothly. Whether you're upgrading your home with new aluminium, steel, or uPVC window and door frames or need repairs for existing fixtures, we have the tools and expertise to get the job done right. From sliding doors to bifold doors, sash doors to French doors, and sash windows to sliding windows, we offer a comprehensive range of installation and repair services tailored to your needs.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="bx bx-file"></i></div>
              <h4 class="title"><a href="">Residential Roofing/Roofing Repairs</a></h4>
              <p class="description">Protect your home with our professional residential roofing and repair services. Our dedicated team specializes in installing and repairing corrugated steel or aluminium roofing, ensuring that your home is shielded from the elements. Whether you need a full roof installation or repairs, including leak detections, we have the knowledge and equipment to deliver quality results. Additionally, we provide steel and aluminium flashings to ensure a seamless and watertight roofing system. Trust us to provide reliable roofing solutions that safeguard your home and provide lasting peace of mind.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><i class="bx bx-tachometer"></i></div>
              <h4 class="title"><a href="">Architectural Aluminium/Steel Cladding Systems</a></h4>
              <p class="description">Enhance the appearance and durability of your building with our expert installation services for architectural aluminium and steel cladding systems. Our experienced team specializes in installing premium cladding solutions such as NuWall and Euro-Line, bringing a contemporary aesthetic to your property. With meticulous attention to detail, we ensure that your cladding system is installed correctly and provides superior protection against the elements. Whether you're renovating an existing structure or starting a new construction project, we deliver exceptional results that exceed your expectations.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon"><i class="bx bx-world"></i></div>
              <h4 class="title"><a href="">General Carpentry</a></h4>
              <p class="description">Transform your interior space with our professional general carpentry services. As installation specialists, we bring skill and precision to every project, from installing jamb liners/reveals to skirtings and architraves. Our experienced team is equipped with the tools and expertise needed to complete your carpentry projects to the highest standards. Whether you're updating your interior trim or undertaking a renovation project, you can count on us to deliver quality craftsmanship and flawless finishes that enhance the beauty and functionality of your home</p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Services Section -->
</template>

<script>
export default {

}
</script>

<style>

</style>