<template>
  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Contact Us</h2>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="contact-about">
              <a href="index.html"><img src="../assets/img/logo.png" alt="" class="img-fluid"></a>
              <p>Join us on our journey as we build the future of construction in New Zealand, one project at a time.</p>
              <div class="social-links">
                <!-- <a href="#" class="twitter"><i class="icofont-twitter"></i></a> -->
                <a href="https://www.facebook.com/groups/rigidpointlimited/" target="_blank" class="facebook"><i class="icofont-facebook"></i></a>
                <a href="https://www.instagram.com/rigidpointlimited/" target="_blank" class="instagram"><i class="icofont-instagram"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
            <div class="info">
              <div>
                <i class="ri-map-pin-line"></i>
                <p>66 Border Rd, Henderson, Auckland 0612</p>
              </div>

              <div>
                <i class="ri-mail-send-line"></i>
                <p>
                  <a href="mailto:roman@rigidpoint.online">roman@rigidpoint.online</a>
                </p>
              </div>

              <div>
                <i class="ri-phone-line"></i>
                <p>
                  <a href="tel:+64 2236 11087">+64 2236 11087</a>
                </p>
              </div>

            </div>
          </div>

          <div class="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
            <form action="https://formspree.io/f/xpzvrdqv" method="post" role="form" class="php-email-form">
              <div class="form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <textarea class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->
</template>

<script>
export default {

}
</script>

<style>

</style>