<template>
  <!-- ======= F.A.Q Section ======= -->
  <section id="faq" class="faq">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Frequently Asked Questions</h2>
        </div>

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>What types of windows and doors do you install?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              We specialize in installing aluminium, steel, and uPVC window and door frames, including sliding doors, bifold doors, sash doors, French doors, sash windows, and sliding windows.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="200">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>Do you offer repair services for windows and doors?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              Yes, we provide expert repair services for a wide range of window and door fixtures, ensuring smooth operation and longevity.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="300">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>What types of roofing materials do you work with?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              We specialize in installing and repairing corrugated steel and aluminium roofing, as well as providing steel and aluminium flashings for a complete roofing solution.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="400">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>How do I know if my roof needs repairs?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              Signs such as leaks, missing or damaged shingles, and sagging areas indicate that your roof may need repairs. Our team can conduct a thorough inspection and provide recommendations based on your roof's condition.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>What are the benefits of installing architectural cladding systems?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              Architectural cladding systems offer enhanced durability, weather resistance, and aesthetic appeal for your building's exterior. They also provide insulation and energy efficiency benefits.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>Can you help me choose the right cladding system for my project?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              Yes, our experienced team can assess your project requirements and recommend the most suitable cladding system to meet your needs and preferences.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>What types of carpentry services do you offer?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              We provide a wide range of general carpentry services, including installation of jamb liners/reveals, skirtings, architraves, and more.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>Do you offer custom carpentry solutions?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              Yes, we specialize in delivering custom carpentry solutions tailored to your specific requirements and design preferences.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>How can I request a quote for your services?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              You can easily request a quote by contacting us through our website or giving us a call. Our team will be happy to discuss your project and provide a free estimate.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

        <div class="row faq-item d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="500">
          <div class="col-lg-5">
            <i class="ri-question-line"></i>
            <h4>Are you licensed and insured?</h4>
          </div>
          <div class="col-lg-7">
            <p>
              Yes, we are fully licensed and insured, providing peace of mind to our clients throughout the duration of their projects.
            </p>
          </div>
        </div><!-- End F.A.Q Item-->

      </div>
    </section><!-- End F.A.Q Section -->
</template>

<script>
export default {

}
</script>

<style>

</style>