<template>
  <HeaderComponent />
  <HeroComponent />

  

  <main id="main">

    <ClientsSection />

    


    <ServicesSection />

    

    <!-- ======= More Services Section ======= -->
    <section id="more-services" class="more-services">
      <div class="container">
        <p>At Rigid Point Installation, these values guide everything we do, from the initial consultation to the final installation. We are committed to exceeding our clients' expectations and setting new standards of excellence in the industry.</p>
        <div class="row">
          <div class="col-md-6 d-flex align-items-stretch">
            <div class="card" style='background-image: url("@/assets/img/more-services-1.jpg");' data-aos="fade-up" data-aos-delay="100">
              <div class="card-body">
                <h5 class="card-title"><a href="">Professionalism</a></h5>
                <p class="card-text">We approach every project with the highest level of professionalism, conducting ourselves with integrity, honesty, and respect at all times. Our commitment to professionalism ensures that every interaction with our clients, partners, and team members is characterized by clear communication, accountability, and excellence.</p>
                
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-stretch mt-4 mt-md-0">
            <div class="card" style='background-image: url("@/assets/img/more-services-2.jpg");' data-aos="fade-up" data-aos-delay="200">
              <div class="card-body">
                <h5 class="card-title"><a href="">High Safety Standards</a></h5>
                <p class="card-text">The safety of our team members, clients, and the community is our top priority. We adhere to strict safety protocols and procedures to mitigate risks and create a secure working environment. By prioritizing safety in everything we do, we ensure that our projects are completed without incident and with the well-being of all stakeholders in mind.</p>
                
              </div>
            </div>

          </div>
          <div class="col-md-6 d-flex align-items-stretch mt-4">
            <div class="card" style='background-image: url("@/assets/img/more-services-3.jpg");' data-aos="fade-up" data-aos-delay="100">
              <div class="card-body">
                <h5 class="card-title"><a href="">Quality Job</a></h5>
                <p class="card-text">We are dedicated to delivering exceptional quality in every aspect of our work. From meticulous craftsmanship to attention to detail, we strive for excellence in all our installations and repairs. By maintaining rigorous quality standards, we guarantee that our clients receive durable, reliable, and aesthetically pleasing results that stand the test of time.</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 d-flex align-items-stretch mt-4">
            <div class="card" style='background-image: url("@/assets/img/more-services-4.jpg");' data-aos="fade-up" data-aos-delay="200">
              <div class="card-body">
                <h5 class="card-title"><a href="">Dust-Free Deadlines</a></h5>
                <p class="card-text">We understand the importance of meeting deadlines without compromising quality. Our efficient project management processes and skilled team enable us to complete projects on schedule, ensuring that our clients' timelines are met without unnecessary delays. We take pride in our ability to deliver on time, every time, while minimizing disruptions and maintaining a clean work environment throughout the process.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End More Services Section -->
    

    <!-- ======= Portfolio Section ======= -->
    <section id="portfolio" class="portfolio">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Portfolio</h2>
          <p>Discover the unique features that set our portfolio apart and showcase our exceptional work.</p>
        </div>

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="400">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="@/assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Custom windows replacement</h4>
                <div class="portfolio-links">
                  <a href="@/assets/img/portfolio/portfolio-1.jpg" data-gall="portfolioGallery" class="venobox" title="App 1"><i class="bx bx-plus"></i></a>
                  <a href="#!" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="@/assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Standard Door replacement</h4>
                <div class="portfolio-links">
                  <a href="@/assets/img/portfolio/portfolio-2.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="bx bx-plus"></i></a>
                  <a href="#!" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="@/assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>French Doors Installation</h4>
                <div class="portfolio-links">
                  <a href="@/assets/img/portfolio/portfolio-3.jpg" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-plus"></i></a>
                  <a href="#!" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="@/assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Window Replacement</h4>
                <div class="portfolio-links">
                  <a href="@/assets/img/portfolio/portfolio-4.jpg" data-gall="portfolioGallery" class="venobox" title="Card 2"><i class="bx bx-plus"></i></a>
                  <a href="#!" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="@/assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Sliding Window</h4>
                <div class="portfolio-links">
                  <a href="@/assets/img/portfolio/portfolio-5.jpg" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-plus"></i></a>
                  <a href="#!" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="@/assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
              <div class="portfolio-info">
                <h4>Window Installation: Clean Interior</h4>
                <div class="portfolio-links">
                  <a href="@/assets/img/portfolio/portfolio-9.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="bx bx-plus"></i></a>
                  <a href="#!" title="More Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Portfolio Section -->
    
    <faq-section />
    <ContactSection />
    
    </main>
</template>



<script>
import $ from 'jquery';
import HeaderComponent from './components/HeaderComponent.vue'
import HeroComponent from './components/HeroComponent.vue'
// import CountsSection from './components/CountsSection.vue'
import ClientsSection from './components/ClientsSection.vue'
import ContactSection from './components/ContactSection.vue';
// import TestimonialsSection from './components/TestimonialsSection.vue';
import FaqSection from './components/FaqSection.vue';
import ServicesSection from './components/ServicesSection.vue';
// import FeaturesSection from './components/FeaturesSection.vue';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    HeroComponent,
    // CountsSection,
    ServicesSection,
    // FeaturesSection,
    ClientsSection,
    ContactSection,
    FaqSection,
    // TestimonialsSection,
  },
  mounted() {
    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function() {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function() {
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function() {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(event.target) && container.has(event.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }

    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, #mobile-nav');

    $(window).on('scroll', function() {
      var cur_pos = $(this).scrollTop() + 80;

      nav_sections.each(function() {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    ///
    $(window).on('scroll', function() {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
        $('.back-to-top').fadeIn('slow');
      } else {
        $('#header').removeClass('header-scrolled');
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function() {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo');
      return false;
    });

  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
