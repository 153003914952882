<template>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

<div class="container">
  <div class="row">
    <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
      <h1 data-aos="fade-up">Welcome to RIGIDPOINT Ltd.</h1>
      <h2 data-aos="fade-up" data-aos-delay="400">
        At Rigid Point Ltd, we're not just another construction company. We are on a mission to redefine the standards of excellence in the New Zealand construction industry. Our ultimate goal is to evolve into a leading developer, crafting residential homes that not only stand the test of time but also enrich the communities we serve.
      </h2>
      <div data-aos="fade-up" data-aos-delay="800">
        <a href="#about" class="btn-get-started scrollto">Get Started</a>
      </div>
    </div>
    <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
      <img src="../assets/img/hero.svg" class="img-fluid animated" alt="">
    </div>
  </div>
</div>

</section><!-- End Hero -->
</template>

<script>
export default {

}
</script>

<style>

</style>