import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/vendor/icofont/icofont.min.css';
import 'remixicon/fonts/remixicon.css';
import 'boxicons/css/boxicons.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


// import './assets/js/main.js';
// import $ from 'jquery';



createApp(App).mount('#app')
